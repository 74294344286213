import React, { useLayoutEffect, useRef } from 'react';
import { useRouter } from '../../components';
import { SiteLink } from '../../components';
import BottomDrawer from '../../components/bottomDrawer'

export function BottomDock(props) {
  const links = [
    { url: '/caravans', title: 'CARAVANS', icon: 'caravans.png', iconHover: 'caravans.png' },
    { url: '/cabins', title: 'CABINS', icon: 'cabins.png', iconHover: 'cabins.png' },
    { url: '/camping', title: 'CAMPING', icon: 'camping.png', iconHover: 'camping.png' },
    { url: '/marina', title: 'MARINA', icon: 'marina.png', iconHover: 'marina.png' },
    { url: '/facilities', title: 'FACILITIES', icon: 'facilities.png', iconHover: 'facilities.png' }
  ];

  //const path = usePath();
  const { pathname } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  }

  const linkElts = links.map((link, index) =>
    <li key={index} className={`bottomMenu__li ${isCurrent(link.url) ? 'selected' : ''}`}>
      <SiteLink to={link.url} className={`bottomMenu__link bottomMenu__link--${index}`}>
        <div className="icon">
          <img className="normal" src={`/assets/navicons/${link.icon}`} alt={link.title} />
          <img className="selected" src={`/assets/navicons/${link.iconHover}`} alt={link.title} />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  );

  const bottomMenu = useRef(null);
  useLayoutEffect(() => {
    //console.log('useLayoutEffect');
    const slider = new BottomDrawer(bottomMenu.current);
    setTimeout(() => slider.init(), 1000)
  }, [])

  return (
    <>
      <nav className="bottomMenu" ref={bottomMenu}>
        <div className="bottomMenu__bar">
          <div className="bottomMenu__line1"></div>
          <div className="bottomMenu__line2"></div>
        </div>
        {/* <div className="bottomMenu__bg"></div> */}
        <ul className="bottomMenu__links">
          {linkElts}
        </ul>
      </nav>
      <div className="bottomMenuPadding"></div>
    </>
  );
}
